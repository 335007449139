import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import CeoCircularProgress from "./CeoCircularProgress";
import CeoworkCircle from "./CeoworkCircle";
import { jwtDecode } from "jwt-decode";
import { useTheme } from "./Theme/Themecontext";
import { useTranslation } from "react-i18next";

function CeoCard({ isRefresh }) {
  const { t, i18n } = useTranslation();

  // console.log('refresh from card',isRefresh)
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [attendance, setAttendance] = useState([]);
  const { styles } = useTheme();
  const { component, bluetext, border } = styles;

  // console.log(attendance, "attendance")

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${baseUrl}/ceo/getMasterInfo`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAttendance(data.result, "ceo");
          console.log(data, "fetchAttendance");
        } else {
          throw new Error("Failed to fetch booking details");
        }
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };

    fetchAttendance();
  }, [isRefresh]);

  const getColorForTitle = (title) => {
    switch (title) {
      case "atOffice":
        return "#006400";
      case "atRemote":
        return "#90EE90";
      case "atLeave":
        return "#808080";
      default:
        return "#000000";
    }
  };

  const getStatusValue = (title) => {
    switch (title) {
      case "atOffice":
        return 1;
      case "atRemote":
        return 2;
      case "atLeave":
        return 0;
      default:
        return -1;
    }
  };
  return (
    <div
      style={window.innerWidth < 768 ? component : {}}
      className=" !border-hidden md:p-0 md:bg-transparent rounded-[10px] h-full"
    >
      {/* <div
        style={bluetext}
        className={`mb-2  md:hidden flex justify-between ${
          i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <p className=" text-[16px] font-[600]">{t("empOverview")}</p>
        <Link to="/home">
          <span class="material-symbols-outlined">refresh</span>
        </Link>
      </div> */}
      <div className="grid grid-flow-col !md:grid-cols-2 gap-2 xl:gap-5  justify-between scroll-container ">
        {attendance.map((item, index) => (
          <div
            style={component}
            key={index}
            className="border-[1px] cursor-pointer border-[#787878] border-opacity-[50%] w-[280px] min-[375px]:w-[340px] min-[425px]:w-[360px] sm:w-[350px] h-[200px] xl:w-[350px] rounded-[10px] xl:mt-0"
          >
            <div className="my-2">
              <div className="text-center ">
                <p className="font-medium">{item.title}</p>
              </div>
              <div className="text-[12px] md:text-[16px] flex gap-5 text-center justify-between px-2 mt-2">
                {item.overAllReport.map((item1, index1) => (
                  <Link
                    to={`/home/ceodashboard/ceouserlist?status=${getStatusValue(
                      item1.title
                    )}&office=${item.title}`}
                  >
                    <div key={index1} className="">
                      <CeoworkCircle
                        percentage={(item1.number / item.totalUser) * 100}
                        color={getColorForTitle(item1.title)}
                      />

                      <p className="font-medium">
                        {item1.number}/{item.totalUser}
                      </p>

                      {item1.title === "atOffice" ? (
                        <>
                          <p className="">On Duty</p>
                          <p className="">[At Office]</p>
                        </>
                      ) : item1.title === "atRemote" ? (
                        <>
                          <p className="">On Duty</p>
                          <p className="">[At Remote]</p>
                        </>
                      ) : item1.title === "atLeave" ? (
                        <>
                          <p className="">Leave/</p>
                          <p className="">Absent</p>
                        </>
                      ) : null}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CeoCard;
