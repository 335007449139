import React from "react";

const enTranslations = {
  "welcome": "Welcome",
    "workingHours": "Working Hours",
    "today": "Today",
    "clockIn": "Clock-In",
    "clockOut": "Clock-Out",
    "location": "Location",
    "inbox": "Inbox",
    "seemore": "See More",
    "inboxItems": {
      "item1": "Smart Document",
      "item2": "Mom",
      "item3": "Meeting",
      "item4": "Tasks"
    },
  "mostusedservice": "Most used services",
  "mostusedItem": {
    "item1": "Smart Service",
    "item2": "Meeting Calendar",
    "item3": "Task",
  },
  "knowledgehub": "Knowledge Hub",
    "knowledgeItem": {
      "item1": "Employee Directory",
      "item2": "Shared Folder",
      "item3": "Links & Videos",
      "item4": "My Report",
      "item5": "My Assets",
      "item6": "Groups"
    },
  "allmytasks": "All My Task",
  "notask": "No Task",
  "mytaskcard": "My Task Card",
  "taskname": "Task Name",
  "assignedBy": "Assigned By",
  "acceptedby": "Accepted By",
  "duedate": "Due date",
  "meetingforToday": "Meeting For Today",
  "mymeeting": "My Meeting",
  "meetingName": "Meeting Name",
  "meetingdatetime": "Meeting Date & Time",
  "meetingloc": "Meeting Location",
  "meetingorg": "Meeting Organizer",
  "momcreator": "Mom Creater",

  "empOverview": "Employee Overview",

  "projects": "Projects",
  "myprojectcard": "My Project Card",
  "projectname": "Project Name",
  "description": "Description",
  "projmanager": "Project Manager",
  "teammembers": "Team Member",
  "startdate": "Start Date",
  "enddate": "End Date",
  "duration": "Duration",
  "noproject": "No Project!!",
  "assignedTask": "Assigned Task",
  "assignto": "Assigned To",
  "assignby": "Assigned By",
  "priority": "Priority",
  "completed": "Complete",
  "inprogress": "In Progress",
  "confirmurPart": "Confirm Your Participation",
  "yes": "Yes",
  "no": "No",
  "mayBe": "Maybe",
  "attendees": "Attendees",
  "notesTaker": "Notes Taker",
  "createmom": "Create MOM",
  "edit": "Edit",
  "cancelmeet": "Cancel Meeting",
  "nodocument": "No Document",

  "contentItems": {
    "item0": "Dashboard",
    "item1": "Smart Attendance",
    "item2": "Smart Service",
    "item3": "Meeting Calendar",
    "item4": "My Project",
    "item5": "My Tasks",
    "item6": "Smart Document",
    "item7": "Mom",
    "item8": "My Report",
    "item9": "Shared Folder",
    "item10": "Links & Videos",
    "item11": "Employee Directory",
    "item12": "Groups",
    "item13": "My Assets",
    "item14": "AI Note",
    "item15": "AI Document Engine",
    "item16": "BOT",
    "item17": "OCR & Scan",
    "item18": "Chat",
    "item19": "Smart IT Support",
    "item20": "Ceo Dashboard",
    "contentItems": {
      "dashboard": "CEO Dashboard"
    },
    "item21": "Request",
    "item22": "Payroll Report",
  },


  

  "ceoInboxItem": {
    "item1": "Mom",
    "item2": "Smart Document",
    "item3": "Smart Service",
  },


    "hrproduct": "HR & Productivity",
    "advanceai": "Advanced AI",
    "newDoc": "New Document",

  "titless": {
    "inbox": "Inbox",
    "sentItems": "Sent Items",
    "archive": "Archive",
    "allDocuments": "All Documents",
    "viewMore": "View More",
    "Approved": " Approved",
    "allrequest" : "All Request",
    "pending": "Pending",
    "rejected": "Rejected",
  },

// task
  "titles": {
    "allTask": "All Task",
    "inProgressTask": "In Progress Task",
    "completedTask": "Completed Tasks",
    "pendingTask": "Pending Task",
    "overAllTask": "OverAllTask"
  },

  // Project
  "titlesss": {
    "allProject": "All Project",
    "inProgress": "In Progress",
    "completed": "Completed",
    "pending": "Pending",
    "hold": "Hold"
  },

  // itsupport
  "titlessss": {
    "openTickets": "Open Tickets",
    "inProgress": "In Progress",
    "closed": "Closed",
    "allTickets": "All Tickets",
  },

  // ceoemploye
  "titlesssss": {
    "attendance":"Attendance",
    "meeting": "Meetings",
    "task": "Tasks",
    "request": "Request",
    "document": "Documents",
  },

  "smartSupport": "Smart IT Support",
  "shareFolder": "Shared Folder",
  "smartDocument": "Smart Document",
  "newfolder": "New Folder",
  "goBack": "Go Back",
  "back": "Back",
  "minutesofMeeting": "Minutes of Meeting",
  "newMom": "New Mom",
  "mytask": "My Task",
  "newtasks": "New Task",
  "overalltask": "Over All Task",
  "createTask": "Create Task",
  "createproj": "Create Project",
  "viewMore": "View More",
  "meeting": "Meeting",
  "myTasks": "My Tasks",
  "smartServices": "Smart Services",
  "meetingCalendar": "Meeting Calendar",
  "task": "Task",
  "noMeetings": "No Meeting",
  "createmeet": "Create Meeting",
  "employeeId": "Emp Id",
  "dateofjoin": "DOJ",
  "qualification": "Qualification",
  "supervisor": "Supervisor",
  "annuallevbal": "Annual Leave Balance",
  "annualsickbal": "Annual Sick Leave Balance",
  "theme": "Themes",
  "changePass": "Change Password",
  "logOut": "Logout",
  "public": "Public",
  "private": "Private",
  "newproject": "New Project",
  "attendanceRep": "Attendance Report",
  "smartService": "Smart Service",
  "pending": "Pending",
  "forapproval": "For Approval",
  "approved": "Approved",
  "rejected": "Rejected",
  "request": "Request",
  "newrequest": "New Request",
  "requestLeave": "Request Leave",
  "requestSickleave": "Request Sick Leave",
  "requestworkfromRemote": "Request-Work From Remote",
  "selectcategory": "Select Category",
  "docSelected": "Document Selected",
  "*documentisMandatory": "*Document is Mandatory for sick leave more than two days",
  "others": "Others",
  "casual": "Casual",
  "sick": "Sick",
  "leaverequest": "Leave Request",
  "close": "Close",
  "requestSubmitSucces": "Request submitted successfully!",
  "reason": "Reason",
  "approver": "Approver",
  "starttime": "Start Time",
  "endtime": "End Time",
  "others": "Others",
  "casual": "Casual",
  "fullday": "Full Day",
  "shortLeave": "Short Leave",
  "date": "Date",
  "newservice": "New Service",
  "inprogressProj": "In Progress Projects",
  "document": "Document",
  "reviewed": "Reviewed",
  "view": "View",
  "docHistory": "Document History",
  "relatedAttach": "Related Attachements",
  "docComment": "Document Comments",
  "shareFolderView": "Shared Folder View",
  "upload": "Upload",
  "linksandvideos": "Links and Videos",
  "newlinkvideo": "New Link & Video",
  "empDirectory": "Employee Directory",
  "groupteam": "Group / Team",
  "createGroup": "Create Group",
  "assets": "Assets",
  "createAsset": "Create Asset",
  "AInotes": "AI Notes",
  "startSpeaking": "Press the button and start speaking",
  "listening": "Listening",
  "notListening": "Not Listening",
  "smartitSupport": "Smart IT Support",

  "ticketDetails": "Ticket Details",
  "openTickets": "Open Tickets",
  "closed": "Closed",
  "allTickets": "All Ticets",
  "tickets": "Tickets",
  "noTickets": "No Tickets",
  "createTickets": "Create Tickets",
  "createdBy": "Created By",
  "remark": "Remark",
  "remarkCannotbeempty": "لا يمكن أن تكون الملاحظة فارغة",
  "newTicket": "New Ticket",
  "attachments": "Attachments",
  "title": "Title",
  "noFiles": "No Files",
  "browse": "Browse",
  "cancel": "Cancel",
  "submit": "Submit",
  "addTickets": "Add Tickets",
  "noFiles": "No Files",
  "newMeeting": "New Meeting",
  "newemployee": "New Employee",

  "subject": "Subject",
  "agenda": "Agenda",
  "repeat": "Repeat",
  "everyMonday": "Every Monday",
  "everyTuesday": "Every Tuesday",
  "everyWednesday": "Every Wednesday",
  "everyThursday": "Every Thursday",
  "everyFriday": "Every Friday",
  "attendees": "Attendees",
  "noteTaker": "Note Taker",
  "link": "Link",
  "time": "Time",
  "from": "From",
  "to": "To",
  "cancel": "Cancel",
  "send": "Send",
  "Position": "Position",

  "actionPlan": "Action Plan",
  "linkedproject": "Linked Project",
  "high": "High",
  "medium": "Medium",
  "low": "Low",
  "assignTask": "Assign Task",
  "workFlow": "Work Flow",
  "projtitle": "Project Title",
  "clientname": "Client Name",
  "techStack": "Technical Stack",
  "create": "Create",
  "nameTeamMembers": "Name of the Team Members",
  "nameProjManager": "Name of the Project Manager",
  "id": "Id",
  "reviewedby": "Reviewed By",
  "signby": "Sign By",
  "employee": "Employee",
  "team": "Team",
  "member": "Member",
  "cc": "CC",
  "save": "Save",
  "editmeeting": "Edit Meeting",
  "selectedEmployees": "Selected Employees",
  "selectEmployees": "Select Employees",
  "assignEmployees": "Assign Employees",
  "updateMeeting": "Update Meeting",
  "UpdateProject": "Update Project",
  "progress": "Progress",
  "inProgress": "In Progress",
  "completed": "Completed",
  "pending": "Pending",
  "hold": "Hold",
  "update": "Update",
  "editTask": "Edit task",
  "through": "Through",
  "forInfo": " For Information",
  "forAction": " For Action",
  "forApprove": "For Approval",
  "preview": "Preview",
  "newSharedFolder": "New Shared Folder",
  "whocanacess": "Who can Access",
  "newLink&Video": "New Link & Video",
  "newTeam": "New Team",
  "groupName": "Group Name",
  "newAssets": "New Assets",
  "serialno": "Serial No",
  "productname": "Product Name",
  "productvalue": "Product Name",
  "smartAttendance": "Smart Attendance",
  "viewallemp": "View More Employee",
  "report": "Report",
  "attendancereport": "Attendance Report",
  "norequest": "No requests ",
  "initiated": "Initiated",
  "users": "Users",
  "payroll": "Payroll",
  "payrollReport": "Payroll Report",
  "selectMonth" : "Select Month",
  "select": "Select",
  "generate": "Generate",
  "selectdate": "Select a date",
  "selecttime": "Select a Time",
  "remote": "Remote",
  "office": "Office",
  "forgetClockin&out": "Forget To Clock-In and Clock-Out",
  "clockOutConfirm": "Clock Out Confirmation",
  "clockInConfirm": "Clock In Confirmation",
  "AreyouwanttoClockOut": "Are you sure you want to Clock Out?",
  "AreyouwanttoClockIn": "Are you sure you want to Clock In?",
  "forgotclckin&out": "Forgot Clock In / Out",
  "timezone": "Time Zone Change",
  "newchat": "New Chat",
  "selectTimeZone":"Select Time Zone",
  

  };

export default enTranslations;
