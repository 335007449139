import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import DropdownNotification from "./DropdownNotification";
import DropdownUser from "./DropdownUser";
import { useEffect, useState } from "react";
import HeaderHome from "./Headerhome";
import HeaderAllPage from "./HeaderAllPage";
import CeoInbox from "./CeoInbox";
import { ThemeSelector } from "../components/Theme/Themecontext";
import { useTranslation } from "react-i18next";

const Header = ({ setHeaderOpen }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const isDashboard = location.pathname === "/home";
  setTimeout(() => {
    setIsRefresh(false);
  }, 5000);

  const [isRefresh, setIsRefresh] = useState(false);


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  return (
    <>
      {/* desktop */}
      <header className=" hidden lg:block relative top-0 w-full border-b-2">
        <div className={`flex w-full h-[90px] flex-grow items-center justify-between py-4 px-4 md:px-6 2xl:px-11 ${i18n.language === 'ar' ? 'flex-row-reverse justify-end' : 'flex-row justify-start'}`}>
          <div className="lg:flex hidden ">
            <CeoInbox isRefresh={isRefresh} />
          </div>
          {/* <div>
            <ThemeSelector/>
          </div> */}

          <div className="flex items-center gap-2 sm:gap-4 lg:hidden"></div>

          

          <div className={`flex w-[350px] items-center gap-3 sm:gap-7 ${i18n.language === 'ar' ? 'flex-row-reverse justify-end' : 'flex-row justify-start'}`}>
            <div className={`flex gap-1 text-[#787878] items-center px-2 `}>
              <button onClick={() => changeLanguage('en')}>En</button> | <button onClick={() => changeLanguage('ar')}>Ar</button>
            </div>
            <Link to="/home/notification">
              <svg
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_4300_218"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#222222" />
                </mask>
                <g mask="url(#mask0_4300_218)">
                  <path
                    d="M5.7998 18.3692V17.5692H6.6153V10.6462C6.6153 9.27299 7.05789 8.07807 7.94305 7.0614C8.82839 6.04474 9.9473 5.44799 11.2998 5.27115V4.0999C11.2998 3.9054 11.3676 3.74015 11.5033 3.60415C11.639 3.46799 11.8037 3.3999 11.9976 3.3999C12.1914 3.3999 12.3569 3.46799 12.4941 3.60415C12.6312 3.74015 12.6998 3.9054 12.6998 4.0999V5.27115C14.0523 5.44799 15.1712 6.04474 16.0566 7.0614C16.9417 8.07807 17.3843 9.27299 17.3843 10.6462V17.5692H18.1998V18.3692H5.7998ZM11.9911 20.9844C11.5994 20.9844 11.2671 20.8458 10.9941 20.5687C10.7209 20.2915 10.5843 19.9583 10.5843 19.5692H13.4153C13.4153 19.9667 13.2758 20.3019 12.9968 20.5749C12.718 20.8479 12.3827 20.9844 11.9911 20.9844ZM7.4153 17.5692H16.5843V10.6462C16.5843 9.36399 16.1407 8.27932 15.2536 7.39215C14.3664 6.50499 13.2818 6.0614 11.9998 6.0614C10.7178 6.0614 9.63322 6.50499 8.74605 7.39215C7.85889 8.27932 7.4153 9.36399 7.4153 10.6462V17.5692Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </Link>


            
           
            <div className="absolute left-3 lg:relative">

             <Link to="/home/userprofile"> <DropdownUser /> </Link>
             
            </div>
          </div>
        </div>
      </header>
      {/* mobile header  */}

      {isDashboard && <HeaderHome setHeaderOpen={setHeaderOpen} />}

      {!isDashboard && <HeaderAllPage currentPath={location.pathname} />}
    </>
  );
};

export default Header;
