import { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../components/translate/i18';
// import { useTheme } from '../components/Theme/Themecontext';


const DefaultLayout = () => {

  const { t, i18n } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [headerOpen, setHeaderOpen] = useState(false);
  // const {theme} = useTheme()

 


  const location = useLocation();

  const isDashboard = location.pathname === "/home";
  

  return (

    <div className="">
      <div className={`flex h-screen overflow-hidden ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`} >
        {/* <!-- Sidebar Start --> */}

        <I18nextProvider i18n={i18n}>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>

        {/* <!-- Content  Start --> */}
        <div className="relative  flex flex-1 flex-col overflow-hidden">
          {/* <!-- Header Start --> */}
          <Header className="fixed top-0 left-0 w-full z-50" headerOpen={headerOpen}  setHeaderOpen={setHeaderOpen} />

          {/* <!-- Main  --> */}

          <main className={`overflow-y-auto flex-1  `}>

            <div className={`mx-auto max-w-screen overflow-x-hidden p-4 md:p-5 2xl:p-10`}>

              {<Outlet />}

            </div>
          </main>
          {/* Footer start mobile */}
          {isDashboard &&
            <Footer className="fixed top-0 left-0 w-full " />}
        </div>
        </I18nextProvider>
      </div>
    </div>
   
  );
};

export default DefaultLayout;
