import React, { useEffect, useState } from "react";
import DropdownUser from "./DropdownUser";
import { Link } from "react-router-dom";
import { useTheme } from "./Theme/Themecontext";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HeaderHome({ setHeaderOpen }) {
  const { t, i18n } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [userInfo, setUserInfo] = useState({});
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { styles, theme, isSystemLight } = useTheme();
  const { component, Svgcolor, bg } = styles;

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
    setHeaderOpen(true);
  };

  const closeNavbar = () => {
    setShowSideMenu(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    try {
      fetch(`${baseUrl}/user/userDetails`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch booking details");
          }
        })
        .then((data) => {
          setUserInfo(data.result);
        })
        .catch((error) => {
          console.error("Error fetching booking details:", error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl]);

  const handleLogout = (event) => {
    if (event) {
      event.preventDefault();
    }

    localStorage.clear();
    window.location.href = "/login";
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleShare = async (e) => {
    e.stopPropagation();

    const shareData = {
      title: "My Business Card",
      text: `
        Name: ${userInfo.name}
        Designation: ${userInfo.position}
        Employee ID: ${userInfo.empID}

        Mobile: ${userInfo.mobile}
        Email: ${userInfo.email}
      Company: Meerana Technologies
        Web Site: https://meeerana.ae
      `,
      url: "https://meeerana.ae",
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        toast.success("Business card shared successfully");
      } else {
        await navigator.clipboard.writeText(
          shareData.text + "\n" + shareData.url
        );
        toast.info("Business card details copied to clipboard");
      }
    } catch (err) {
      console.error("Error sharing details:", err);
      toast.error("Failed to share business card");
    }
  };

  return (
    <>
      {/* Header */}
      <header
        className={`lg:hidden relative flex w-full h-[70px] bg-[#1E3354] drop-shadow-1`}
      >
        <div className={`flex w-full`}>
          <div
            className={`flex h-full items-center justify-center ml-3 absolute ${
              i18n.language === "ar" ? "right-2" : "left-0"
            }`}
          >
            <Link to="/home/userprofile">
              <DropdownUser />
            </Link>
          </div>

          <div className="py-3 w-full">
            <div
              className={`w-[200px] absolute ${
                i18n.language === "ar" ? "right-16" : "left-16"
              }`}
            >
              <p className="text-[12px] text-[#f1f1f1] font-bold">
                Welcome {userInfo.name}
              </p>
              {/* <p className="text-[12px] text-[#f1f1f1]">{userInfo.position}</p> */}
              <div
                onClick={togglePopup}
                className="flex p-0.5 w-[150px] items-center bg-white text-[#1E3354] rounded-md cursor-pointer"
              >
                <span className="material-symbols-outlined">id_card</span>
                <button className="px-2 text-[12px]">My Business Card</button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex gap-1 text-white items-center px-2 absolute top-6 ${
            i18n.language === "ar" ? "left-2" : "right-0"
          }`}
        >
          <button onClick={() => changeLanguage("en")}>En</button> |{" "}
          <button onClick={() => changeLanguage("ar")}>Ar</button>
        </div>

        {/* Business Card Popup */}

        {isOpen && (
          <div
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setIsOpen(false);
              }
            }}
            className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50"
          >
            <div
              style={bg}
              className="p-8 border rounded-lg shadow-lg w-[80%] max-w-md relative bg-white"
            >
              <div className="flex justify-between items-center text-[14px] mb-3">
                <h1 className="font-bold">My Business Card</h1>
                <button
                  className="material-symbols-outlined cursor-pointer"
                  onClick={(e) => handleShare(e)}
                  aria-label="Share business card"
                >
                  share
                </button>
              </div>

              {/* Business Card Details */}
              <div
                style={component}
                className="border text-[12px] p-5 rounded-md"
              >
                <h1 className="font-bold text-[14px]">{userInfo.name}</h1>
                <p className="text-[14px]">{userInfo.position}</p>
                <div className="flex items-center mt-1">
                  <svg
                    style={Svgcolor}
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_4195_968"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_4195_968)">
                      <path d="M18.0556 19.3756C16.4365 19.2256 14.8541 18.7618 13.3086 17.9843C11.7631 17.2067 10.3474 16.1717 9.06139 14.8793C7.77555 13.5872 6.75214 12.1757 5.99114 10.6448C5.23031 9.114 4.76656 7.539 4.59989 5.91984C4.56656 5.56434 4.66897 5.25559 4.90714 4.99359C5.14514 4.73159 5.44272 4.60059 5.79989 4.60059H7.62289C7.94472 4.60059 8.21939 4.6945 8.44689 4.88234C8.67456 5.07017 8.84097 5.32309 8.94614 5.64109L9.39214 7.17559C9.4383 7.40509 9.42581 7.63267 9.35464 7.85834C9.28347 8.084 9.16839 8.2635 9.00939 8.39684L6.81539 10.4428C7.22556 11.2043 7.66656 11.9088 8.13839 12.5563C8.61022 13.2038 9.11889 13.805 9.66439 14.3598C10.2291 14.9242 10.8461 15.4477 11.5154 15.9303C12.1846 16.413 12.9191 16.8595 13.7191 17.2698L15.9826 15.0428C16.1288 14.8838 16.2826 14.7848 16.4441 14.7458C16.6056 14.7067 16.8011 14.7038 17.0306 14.7371L18.3594 15.0428C18.6812 15.1262 18.9351 15.2881 19.1211 15.5286C19.307 15.7689 19.3999 16.0564 19.3999 16.3911V18.1756C19.3999 18.5328 19.2647 18.8303 18.9944 19.0683C18.7241 19.3065 18.4111 19.4089 18.0556 19.3756ZM6.47114 9.65434L8.49039 7.78909C8.55439 7.73775 8.59606 7.66725 8.61539 7.57759C8.63456 7.48775 8.63131 7.40442 8.60564 7.32759L8.15739 5.70834C8.13172 5.60567 8.08689 5.52875 8.02289 5.47759C7.95872 5.42625 7.87539 5.40059 7.77289 5.40059H5.69989C5.62289 5.40059 5.55881 5.42625 5.50764 5.47759C5.45631 5.52875 5.43064 5.59284 5.43064 5.66984C5.44981 6.30317 5.55689 6.95409 5.75189 7.62259C5.94672 8.29125 6.18647 8.9685 6.47114 9.65434ZM14.5711 17.6391C15.1816 17.9278 15.8356 18.1387 16.5329 18.2718C17.2301 18.4052 17.8293 18.4943 18.3306 18.5391C18.4076 18.5391 18.4717 18.5134 18.5229 18.4621C18.5742 18.4108 18.5999 18.3467 18.5999 18.2698V16.2121C18.5999 16.1096 18.5742 16.0263 18.5229 15.9621C18.4717 15.8981 18.3948 15.8533 18.2921 15.8276L16.9171 15.5236C16.8403 15.4979 16.7731 15.4948 16.7154 15.5141C16.6576 15.5333 16.5966 15.5749 16.5326 15.6391L14.5711 17.6391Z" />
                    </g>
                  </svg>

                  <p className="text-[13px] ml-2">{userInfo.mobile}</p>
                </div>
                <div className="flex items-center mt-2">
                  {/* Email Icon */}
                  <svg
                    style={Svgcolor}
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path for Email Icon */}
                    <mask
                      id="mask0_4195_977"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_4195_977)">
                      <path d="M4.81589 18.1998C4.41056 18.1998 4.07331 18.0651 3.80414 17.7958C3.53497 17.5265 3.40039 17.189 3.40039 16.7833V7.20806C3.40039 6.80256 3.53497 6.46647 3.80414 6.1998C4.07331 5.93314 4.41056 5.7998 4.81589 5.7998H19.1849C19.5902 5.7998 19.9275 5.93447 20.1966 6.2038C20.4658 6.47314 20.6004 6.81064 20.6004 7.2163V16.7916C20.6004 17.1971 20.4658 17.5331 20.1966 17.7998C19.9275 18.0665 19.5902 18.1998 19.1849 18.1998H4.81589ZM12.0004 12.3153L4.20039 7.6093V16.7843C4.20039 16.9638 4.25806 17.1113 4.37339 17.2268C4.48889 17.3421 4.63639 17.3998 4.81589 17.3998H19.1849C19.3644 17.3998 19.5119 17.3421 19.6274 17.2268C19.7427 17.1113 19.8004 16.9638 19.8004 16.7843V7.6093L12.0004 12.3153ZM12.0004 11.0748L19.4926 6.5998H4.50814L12.0004 11.0748ZM4.20039 7.6093V6.5998V16.7843C4.20039 16.9638 4.25806 17.1113 4.37339 17.2268C4.48889 17.3421 4.63639 17.3998 4.81589 17.3998H4.20039V7.6093Z" />
                    </g>
                  </svg>
                  <p className="text-[13px] ml-2">{userInfo.email}</p>
                </div>
                <div className="flex justify-center mt-5">
                  <QRCode
                    size={180}
                    value={`BEGIN:VCARD\nVERSION:3.0\nFN:${userInfo.name}\nTEL:${userInfo.mobile}\nEMAIL:${userInfo.email}\nTITLE:${userInfo.position}\nEND:VCARD`}
                    fgColor={
                      theme === "dark" || ("system" && !isSystemLight)
                        ? "white"
                        : "black"
                    }
                    bgColor={
                      theme === "dark" || ("system" && !isSystemLight)
                        ? "black"
                        : "white"
                    }
                  />
                </div>
                {/* <button
                  onClick={togglePopup}
                  className="w-full py-2 mt-5 bg-[#1E3354] text-white rounded-md"
                >
                  Close
                </button> */}
              </div>
            </div>
          </div>
        )}

        {/* Toast Notifications */}
        {/* <ToastContainer /> */}
      </header>
    </>
  );
}

export default HeaderHome;
