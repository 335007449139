import {  createContext, useContext, useState, useEffect,   } from "react";
import ThemeStyles from "./ThemeStyles";


const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  const [isSystemLight, setIsSystemLight] = useState(() => {
    return theme === 'system' && !window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const applyTheme = (newTheme) => {
      if (newTheme === 'system') {
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', systemTheme);
        setIsSystemLight(!window.matchMedia('(prefers-color-scheme: dark)').matches);
      } else {
        document.documentElement.setAttribute('data-theme', newTheme);
        setIsSystemLight(newTheme === 'light');
      }
    };

    applyTheme(theme);
    localStorage.setItem('theme', theme);

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      if (theme === 'system') {
        applyTheme('system');
      }
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, [theme]);

  const styles = ThemeStyles(theme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, styles, isSystemLight }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeSelector = () => {
  const { theme, setTheme } = useTheme(); 
  const styles = ThemeStyles(theme);
  const {input} = styles

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };
  

  return (
   
        <select style={input} className='border-none w-26 ml-4 px-2 rounded-lg' value={theme} onChange={handleThemeChange}>
        <option value="dark">Dark</option>
        <option value="light">Light</option>
        <option value="system">System</option>
      </select>
   
  );
};