import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "./Theme/Themecontext";
import {  CeoInboxItems, initializeCeoInboxItems  } from "./Jsonsidebar";
import { useTranslation } from "react-i18next";



function CeoInbox(isRefresh) {

  const { t, i18n } = useTranslation();
  // const CeoInboxItem = [...CeoInboxItems];
  const { styles } = useTheme()
  const { bluetext } = styles
 
  const handleRefresh = () => {
    window.location.reload();
  };
  const [ceoInboxItem, setCeoInboxItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await initializeCeoInboxItems();
      setCeoInboxItem(items); 
    };
    fetchData(); 
  }, []);

  return (
    <div className="p-5 md:border-hidden md:p-0 md:bg-transparent bg-[#ffffff] border-[1px] border-opacity-[50%] border-[#787878] rounded-[10px] h-full">
      <div className="mb-2 md:hidden flex justify-between">
        <p className="text-[#1E3354] text-[16px] font-[600]">Inbox</p>

        <button onClick={handleRefresh}>
          <span class="material-symbols-outlined">refresh</span>
        </button>
      </div>
      <div className="flex">
      <div className="flex flex-wrap mx-2 gap-2 mt-3  ">
          {ceoInboxItem.map((item, index) => (
            <Link to={item.link} key={index}>
              <div style={bluetext} className="relative flex flex-col justify-center items-center border-[3px] border-[#1E3354] p-2 h-[50px] lg:w-[100px] xl:w-[150px] rounded-[10px]">
               
                <span className="text-[12px] text-center">
                 {t(`ceoInboxItem.${item.label}`)}
                </span>
                <div className="absolute text-white -top-2 -right-1.5 w-[25px] h-[20px] rounded-full bg-[#EA001B]">
                  <p className="text-[10px] text-center mt-0.5">{item.count ?? 0}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CeoInbox;
