import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CeoDashboardCircle = ({ total = 100, values = [100], sum = 100 }) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const { t, i18n } = useTranslation();
  const [radius, setRadius] = useState(70);
  const [stroke, setStroke] = useState(20);
  const [data,setData] = useState({})

  const updateDimensions = () => {
    const width = window.innerWidth;

    if (width <= 640) {
      setRadius(55);
      setStroke(10);
    } else if (width > 640 && width <= 1024) {
      setRadius(70);
      setStroke(15);
    } else {
      setRadius(85);
      setStroke(20);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const circumference = 2 * Math.PI * radius;
  const normalizedRadius = radius - stroke / 2;

  const calculateSegments = (values, total) => {
    let segments = [];
    let cumulativeLength = 0;

    // Ensure that 'values' is an array
    if (Array.isArray(values)) {
      values.forEach((value, index) => {
        if (value !== 0) {
          const segmentLength = (value / total) * circumference;
          const angle = (cumulativeLength / circumference) * 360 + 90;
          const textAngle = angle + ((segmentLength / circumference) * 580) / 2;

          const textX =
            radius + normalizedRadius * Math.cos((textAngle * Math.PI) / 180);
          const textY =
            radius + normalizedRadius * Math.sin((textAngle * Math.PI) / 180);

          segments.push({
            length: segmentLength,
            color: getColor(index),
            offset: cumulativeLength,
            value: value,
            textX: textX,
            textY: textY,
            angle: angle,
          });

          cumulativeLength += segmentLength;
        }
      });
    }

    return segments;
  };

  const getColor = (index) => {
    const colors = ["#008000", "#4ADE80", "#808080"];
    return colors[index];
  };

  const segments = calculateSegments(values, total);

  const indicators = [
    { color: "#008000", label: "off" },
    { color: "#202020", label: "Rem" },
    { color: "#766374", label: "NC" },
  ];

  const fetchattendance = ()=>{
    const token = localStorage.getItem('token')
    try {
      fetch(`${baseUrl}/ceo/getMasterInfo`,{
        method:"GET",
        headers:{
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }
      }).then((response) => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw new Error("Unable to fetch data");
        }
    })
        .then((data) => {
            console.log(data);
            if (data.isSuccess) {
              // alert("request sent successfully")
              setData(data?.result)
              console.log("data",data?.result,);
            }
        })
        .catch((err) => {
            console.log(err);
        });
  } catch (error) {
    console.log(error);
  }
  }

  useEffect(()=>{
    fetchattendance();
  },[])
  return (
    <div className="relative w-[200px] h-[175px] min-h-[185px] md:w-[250px] md:h-[220px] mt-5 border-[3px] rounded-[10px] border-[#1E3354] p-2">
      <p className="text-center mt-2 md:mt-4 xl:mt-1 font-semibold text-[8px] md:text-[14px]">
        Total Work Force
      </p>
      <div>
        <svg
          className={`inset-0 w-full h-full absolute left-2 md:left-12 lg:left-[55px] xl:left-[35px] top-8 md:top-12 xl:top-8 svg-right ${
            i18n.language === "ar" ? "-right-6" : ""
          }`}
        >
          {segments.map((segment, index) => (
            <g key={index}>
              <circle
                cx={radius}
                cy={radius}
                r={normalizedRadius}
                stroke={segment.color}
                strokeWidth={stroke}
                fill="none"
                strokeDasharray={`${segment.length} ${circumference}`}
                strokeDashoffset={-segment.offset}
                transform={`rotate(90 ${radius} ${radius})`}
              />
              <text
                x={segment.textX}
                y={segment.textY}
                textAnchor="middle"
                className="text-[5px] md:text-[8px] font-semibold"
                fill="#ffffff"
              >
                {segment.value}
              </text>
            </g>
          ))}
        </svg>

        <div className="absolute right-0 md:right-3 custom-right w-[20px] top-[40px] md:top-[60px]">
          {indicators.map((indicator, index) => (
            <div key={index} className="flex flex-col items-center mb-[5px]">
              <p
                className="rounded-full"
                style={{
                  backgroundColor: indicator.color,
                  height: "10px",
                  width: "10px",
                }}
              ></p>
              <p className="text-[10px]">{indicator.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute top-[73px] md:top-[100px] lg:top-[95px] text-[9px] flex flex-col text-center items-center content-right md:left-[75px] lg:left-[85px] xl:left-[80px]">
        <div className="md:text-[16px] font-semibold">
          {sum}/{total}
        </div>
        <div className="md:text-[10px] font-semibold">Attendance Status</div>
      </div>
    </div>
  );
};

export default CeoDashboardCircle;
