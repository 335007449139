import React, { useEffect, useState, useCallback } from "react";
import Clock from "react-live-clock";
import { useTheme } from "./Theme/Themecontext";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import CeoDashboardCircle from "./CeoDashboardCircle";

const CardOne = ({ reloadCounter }) => {
  const { t, i18n } = useTranslation();
  const { styles } = useTheme();
  const { component } = styles;

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [userclkInfo, setUserclkInfo] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [workingHours, setWorkingHours] = useState("00h : 00m");
  const [data, setData] = useState({});
  // console.log(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);


  const calculateWorkingHours = (punchIn, punchOut) => {
    if (!punchIn || !punchOut) return "00h : 00m";
    const start = new Date(punchIn);
    const end = new Date(punchOut);
    const diffMs = end - start;
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${diffHrs.toString().padStart(2, "0")}h : ${diffMins
      .toString()
      .padStart(2, "0")}m`;
  };

  const getLocation = useCallback(
    (lat, lon) => {
      const apiUrl = isChecked ? `${baseUrl}/punch/out` : `${baseUrl}/punch/in`;
      const bodyData = { lat, lon };

      try {
        const token = localStorage.getItem("token");
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            fetchUserclkInfo();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [isChecked, baseUrl]
  );

  const fetchUserclkInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/punch/details`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setIsChecked(data.result.isPunch);
        setUserclkInfo(data.result);

        // Calculate working hours if both clock-in and clock-out times are available
        if (data.result.punchIn && data.result.punchOut) {
          const hours = calculateWorkingHours(
            data.result.punchIn,
            data.result.punchOut
          );
          setWorkingHours(hours);
        } else if (data.result.punchIn && !data.result.punchOut) {
          const now = new Date();
          const hours = calculateWorkingHours(data.result.punchIn, now);
          setWorkingHours(hours);
        }
      } else {
        throw new Error("Failed to fetch clock details");
      }
    } catch (error) {
      console.error("Error fetching clock details:", error);
    }
  }, [baseUrl]);

  const resetWorkingHoursAtMidnight = () => {
    const now = new Date();
    const currentHour = now.getHours();
    if (currentHour === 0) {
      setWorkingHours("00h : 00m");
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };


  const handleConfirmation = (confirmation) => {
    setIsModalOpen(false);

    if (confirmation) {
      getLocation(lat, lon);
    } else {
      console.log("User canceled the confirmation.");
    }
  };



  const getconfirmation = useCallback(
    (lat, lon) => {
      const apiUrl = `${baseUrl}/punch/confirmation`;
      const bodyData = { lat, lon };

      try {
        const token = localStorage.getItem("token");
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setData(data.result)
            setLat(lat);
            setLon(lon);
            setIsModalOpen(true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [baseUrl]
  );



  const handleCheckboxChange = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getconfirmation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              "Please turn on browser location settings to clock in & clock out."
            );
            // getLocation(0.0, 0.0);
          } else {
            console.error("Error getting location:", error);
            alert(
              "Error getting location. Please ensure location services are enabled."
            );
            // getLocation(0.0, 0.0);
          }
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      // getLocation(0.0, 0.0);
    }
  };

  useEffect(() => {
    fetchUserclkInfo();
  }, [fetchUserclkInfo]);

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);

  return (
    <>

      <section>
        <div className="text-center flex justify-center items-center text-white text-[14px] md:text-[18px] font-bold w-full md:w-[300px] lg:w-[350px] xl:w-[360px] h-[30px] p-0.5 rounded-[20px] bg-[#1E3354]">
          <span
            className={`text-white ${i18n.language === "en" ? "block" : "hidden"
              }`}
          >
            {t("workingHours")} - {workingHours} {t("today")}
          </span>
          <span
            className={`flex flex-row gap-2 justify-center text-center text-white ${i18n.language === "ar" ? "block" : "hidden"
              }`}
          >
            {" "}
            <p>{t("today")}</p> {workingHours} - {t("workingHours")}
          </span>
        </div>
        <div className="flex gap-1 justify-center md:justify-start md:gap-10 lg:gap-5">

          <div
            style={component}
            className={`p-2  rounded-[10px] mt-5 ${userInfo.role === 2
              ? "h-[200px] w-[300px] lg:w-[350px] xl:w-[360px]"
              : "h-[175px] min-h-[185px] md:min-w-[300px] w-[190px] md:h-[220px] lg:w-[350px] xl:w-[360px]"
              }`}
          >
            {/* Displaying Working Hours */}
            <div className="p-2 w-full flex flex-col justify-center items-center">
              <Clock
                className={` ${userInfo.role === 2
                  ? "!text-[13px]"
                  : "!text-[13px] md:text-[18px]"
                  }`}
                format={"hh:mm:ss A"}
                style={{ fontSize: "18px" }}
                ticking={true}
              />
              <p
                className={` ${userInfo.role === 2
                  ? "!text-[13px]"
                  : "text-[13px] md:text-[14px] md:mt-2"
                  }`}
              >
                {formatDate(userclkInfo.createDate)}
              </p>
              <div className=" flex flex-row gap-2">
                <p
                  className={` ${userInfo.role === 2
                    ? "!text-[13px] mt-1"
                    : "text-[13px] md:text-[13px] md:mt-1"
                    }`}
                >
                  {t("location")} :{" "}
                  <span>
                    {" "}
                    {isChecked
                      ? userclkInfo.locationIn
                      : userclkInfo.locationOut}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                id="toggle"
                className="sr-only peer"
              />

              <label htmlFor="toggle" className="relative">
                <div
                  className={` ${userInfo.role === 2
                    ? "border-[#787878] border-[.5px] cursor-pointer h-[50px] w-[150px] md:h-[52px] bg-[#ffff] rounded-full flex items-center px-1"
                    : "border-[#787878] border-[.5px] cursor-pointer h-[40px] w-[120px] md:w-[150px] md:h-[52px] bg-[#ffff] rounded-full flex items-center px-1"
                    }`}
                >
                  <div
                    className={`
                      ${userInfo.role === 2
                        ? "h-[50px] w-[50px] md:h-[50px] md:w-[50px] bg-[#fff] cursor-pointer absolute rounded-full transition-all duration-500 grid place-items-center"
                        : "h-[40px] w-[40px] md:h-[50px] md:w-[50px] bg-[#fff] cursor-pointer absolute rounded-full transition-all duration-500 grid place-items-center"
                      }
                      ${isChecked
                        ? i18n.language === "ar"
                          ? userInfo.role === 2
                            ? "right-[0px] !bg-[#1E3354]" // Arabic - User - Move right
                            : "left-[0px] md:left-0 !bg-[#1E3354]" // Arabic - other roles - different position
                          : userInfo.role === 2
                            ? "left-[100px] !bg-[#1E3354]" // English - User - Move left
                            : "left-[80px] md:left-[100px] !bg-[#1E3354]" // English - other roles - different position
                        : i18n.language === "ar"
                          ? userInfo.role === 2
                            ? "right-[100px]  !bg-[#6f7e95]" // Unchecked - Arabic - User
                            : "right-[0px]  !bg-[#6f7e95]" // Unchecked - Arabic - other roles
                          : userInfo.role === 2
                            ? "left-[0px] !bg-[#6f7e95]" // Unchecked - English - User
                            : "left-[1px] !bg-[#6f7e95]" // Unchecked - English - other roles

                      }`}
                  >
                    {isChecked ? (
                      <svg

                        className={`${userInfo.role === 2 ? "h-[22px] w-[22px]" : "h-[22px] w-[22px]"
                          }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 -960 960 960"
                        fill="#e8eaed"
                      >
                        <path d="m798-270-60-60q11-27 16.5-52.5T760-436q0-116-82-200t-198-84q-28 0-54 4.5T374-700l-60-60q38-20 79.5-30t86.5-10q74 0 139.5 28T734-694.5Q783-645 811.5-579T840-436q0 45-11 86.5T798-270Zm52-370L680-810l56-56 170 170-56 56ZM820-24 694-150q-45 33-99.5 51.5T480-80q-74 0-139.5-28T226-184q-49-48-77.5-113T120-436q0-62 18.5-116.5T192-652l-34-34-48 48-56-56 48-48-74-74 56-56L876-80l-56 56ZM480-159q42 0 82-13t74-36L248-594q-23 35-35.5 75.5T200-436q0 116 82 196.5T480-159Zm-38-242Zm114-114Z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`${userInfo.role === 2 ? "h-[22px] w-[22px]" : "h-[22px] w-[22px]"
                          }`}
                        viewBox="0 -960 960 960"
                        fill="#ffff"
                      >
                        <path d="M480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-800q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-440q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-80Zm0-360Zm112 168 56-56-128-128v-184h-80v216l152 152ZM224-866l56 56-170 170-56-56 170-170Zm512 0 170 170-56 56-170-170 56-56ZM480-160q117 0 198.5-81.5T760-440q0-117-81.5-198.5T480-720q-117 0-198.5 81.5T200-440q0 117 81.5 198.5T480-160Z" />
                      </svg>
                    )}
                  </div>
                </div>
                <span
                  className={`absolute w-[100px] top-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer ${isChecked
                    ? i18n.language === "ar"
                      ? userInfo.role === 2
                        ? "left-[55px] md:left-[57px] text-[#1E3354] text-[16px] "  // Arabic - User - Move right
                        : "left-[92px] md:left-[104px] text-[#1E3354] text-[13px] md:text-[16px] " // Arabic - other roles - different position
                      : userInfo.role === 2
                        ? "left-[70px] md:left-[70px] text-[#1E3354] top-1/2" // English - User - Move left
                        : "left-[70px] md:left-[70px] text-[#1E3354] text-[13px] md:text-[16px] " // English - other roles - different position
                    : i18n.language === "ar"
                      ? userInfo.role === 2
                        ? "left-[110px] md:left-[110px] text-[#1E3354] text-[16px] top-1/2"  // Unchecked - Arabic - User
                        : " left-[60px] md:left-[60px] text-[#1E3354] text-[13px] md:text-[16px] "  // Unchecked - Arabic - other roles
                      : userInfo.role === 2
                        ? "left-[110px] md:left-[120px] text-[#1E3354] text-[16px] top-1/2" // Unchecked - English - User
                        : "left-[100px] md:left-[110px] text-[#1E3354] text-[13px] md:text-[16px] " // Unchecked - English - other roles
                    }`}
                >
                  {isChecked ? t("clockOut") : t("clockIn")}
                </span>
              </label>
            </div>

            <div className={` ${userInfo.role === 2
              ? "flex flex-row gap-1 justify-center mt-4" : "flex flex-row gap-1 justify-between md:justify-center mt-1 md:mt-4"}`}>
              <div
                className={` ${userInfo.role === 2
                  ? "flex  text-center gap-1 !text-[13px]"
                  : "flex  text-center gap-1 !text-[11px] md:!text-[13px]"
                  }`}
              >
                <p className="break-all">
                  Clocked-in : Today at{" "}
                  <span>{formatTime(userclkInfo.punchIn)},</span>
                  <p
                    className={` ${userInfo.role === 2
                      ? "text-[13px]"
                      : "text-[11px] md:text-[13px]"
                      }`}
                  >
                    {isChecked ? userclkInfo.locationIn : userclkInfo.locationOut}
                  </p>
                </p>
              </div>


            </div>
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70 p-5">
                <div className={`bg-[#1E3354] border border-[#ffffff] rounded-[20px] lg:w-[700px] xl:w-[900px] min-w-[250px] p-8 shadow-lg transition-transform transform scale-100 hover:scale-105  ${i18n.language === 'ar' ? 'text-right' : 'text-left'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                  <h2 className="text-xl font-bold text-white text-center mb-2">
                    {isChecked ? t("clockOutConfirm") : t("clockInConfirm")}
                  </h2>
                  <p className="text-[14px] text-white text-center mb-1">{data?.message}</p>
                  <p className="text-[14px] text-white text-center mb-1">{t('location')}: {data?.location}</p>
                  <p className="text-[14px] text-white text-center mb-1">{t('date')}: {data?.punchDate}</p>
                  <p className="text-[14px] text-white text-center mb-4">
                    {t('time')}: {new Date(data?.punchTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                  </p>

                  <div className="mt-4">
                    <h2 className="text-lg font-semibold text-yellow-400 text-center mb-4">
                      {isChecked ? t("Are you sure you want to Clock Out?") : t("Are you sure you want to Clock In?")}
                    </h2>
                    <div className="flex justify-center gap-4 mt-4">
                      <button
                        onClick={() => handleConfirmation(true)}
                        className="bg-[#1E3356] border text-white font-bold py-3 px-3 rounded-lg shadow-md hover:bg-[#0d192c] transition duration-200"
                      >
                        <span className="text-lg">✔️ Yes</span>
                      </button>
                      <button
                        onClick={() => handleConfirmation(false)}
                        className="bg-[#1E3354] border text-white font-bold py-3 px-3 rounded-lg shadow-md hover:bg-[#0d192c] transition duration-200"
                      >
                        <span className="text-lg">❌ No</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}


          </div>
          {userInfo.role === 0 ? <CeoDashboardCircle /> : null}
        </div>
      </section>
    </>
  );
};

export default CardOne;















// const getLocation = () => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       async (position) => {
//         const lat = position.coords.latitude;
//         const lon = position.coords.longitude;
//         const apiUrl = isChecked
//           ? 'http://20.174.26.6:3000/v1/punch/out'
//           : 'http://20.174.26.6:3000/v1/punch/in';
//         const bodyData = {
//           lat: lat,
//           lon: lon,
//         };

//         try {
//           const token = localStorage.getItem("token");
//           const userInfo = jwtDecode(token);
//           console.log("jwttoken", userInfo)

//           const response = await fetch(apiUrl, {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${token}`

//             },
//             body: JSON.stringify(bodyData),
//           });
//           const data = await response.json();
//           // window.location.reload();

//           console.log(data);
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       },
//       (error) => {
//         console.error('Error getting location:', error);
//       }
//     );
//   } else {
//     console.error('Geolocation is not supported by this browser.');
//   }
// };

// const handleCheckboxChange = async () => {
//   console.log("Before toggle:", isChecked);
//   // setIsChecked(prevChecked => !prevChecked);
//   console.log("After toggle:", isChecked);
//   getLocation();
//   fetchUserclkInfo();
// };

// const handleCheckboxChange = async () => {
//   console.log("Before toggle:", isChecked);
//   // setIsChecked(prevChecked => !prevChecked);
//   console.log("After toggle:", isChecked);

//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         getLocation();
//         fetchUserclkInfo();
//       },
//       (error) => {
//         if (error.code === error.PERMISSION_DENIED) {
//           alert("Please turn on browser location settings to clock in & clock out.");
//         } else {
//           console.error('Error getting location:', error);
//           alert('Error getting location. Please ensure location services are enabled.');
//         }
//       }
//     );
//   } else {
//     alert('Geolocation is not supported by your browser.');
//   }
// };
